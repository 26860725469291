<template>
    <div>
        <div class="datum-row">
            <div class="datum-title">
                <img src="../assets/userTitleIcon.png" alt="">
                <p>资料设置</p>
            </div>
            <div class="datum">
                    <div>
                        <div class="disCenter">
                            <div class="dis ">
                                <span class="font18 color5B">
                                    头像：
                                </span>
                                <div class="photo">
                                    <img :src="userList.avatar ? url_prefix + userList.avatar : 'https://cdn.ssjzt.com/images/jzt/avatar/20240530/2024053011144521756.png?imageView2/3/w/50/h/50'" style="margin:0 auto;width:120px;height:120px;border-radius: 50%">
                                </div>
                            </div>
                        <el-upload
                                action="https://gz.zxw233.com/api/common/upload_file"
                                class="upload-demo"
                                :http-request="httpRequest"
                                accept=".png,.jpg"
                                :show-file-list="false"
                                :on-change="uploadChange"
                        >
                            <div class="upPhoto font14 marLeft24">上传头像</div>
                        </el-upload>

                        </div>
                        <div class="marTop46">
                            <div class="disCenter">
                                <img class="userIcon" src="../assets/userIcon.png" alt="">
                                <span class="color40">修改昵称：</span>
                            </div>
                            <div class="userInput disCenter">
                                <input type="text" v-model="username" class="inputstyle flex1" placeholder="请输入昵称">
                                <div class="userSubmit" @click="userSubmitFn(1)">
                                    提交
                                </div>
                            </div>
                             <div class="disCenter marTop25">
                                 <img class="setPassword" src="../assets/setPassword.png" alt="">
                                 <span class="color40">修改密码:</span>
                             </div>
                            <div style="margin-top: 16px">
                                <div class="password">
                                    <input type="password" autocomplete='off' v-model="password" class="inputstyle"  placeholder="请输入原始密码">

                                </div>
                                <div class="password">
                                    <input type="password" autocomplete='off' v-model="new_password" class="inputstyle" placeholder="请输入新密码">
                                </div>
                                <div class="dis width454 justify-between">
                                    <div></div>
                                    <div class="userSubmit" @click="userSubmitFn(3)">
                                        立即修改
                                    </div>
                                </div>
                            </div>
                            <div class="disCenter">
                                <img class="siteIcon" src="../assets/siteIcon.png" alt="">
                                <span class="color40">绑定地址:</span>
                            </div>
                            <div class="textarea">
                                <textarea class="inputstyle" style="padding: 10px;box-sizing: border-box;resize: none;" name="" id="" cols="30" rows="10" v-model="express" placeholder="收货地址，名字，电话"></textarea>

                            </div>
                          <div class="dis width454 paBottom100 justify-between">
                              <div></div>
                              <div class="Submit" @click="userSubmitFn(2)">
                                  提交
                              </div>
                          </div>
                        </div>

                    </div>

                    </div>
            </div>
        </div>
</template>

<script>
    import api from '../lnit/api'
    import axios from 'axios'
    export default {
        name: "setUser",
        data(){
            return{
                username:null,
                express:null,
                fileList:[],
                url_prefix:null,
                userList:{
                    avatar: null,
                },
                formDate:null,
                password:null,
                new_password:null,
            }
        },
        created() {
          this.url_prefix = api.url_prefix
            this.getUserFn()
        },
        methods:{
            getUserFn(){
              this.$post({
                  url:api.get_user,
              }).then((res)=>{
                  this.userList = res.data.user
                  this.username = res.data.user.nickname
                  this.express =res.data.user.shdz
              })
            },
            userSubmitFn(i){
                if(i ==1){
                    if(!this.username){
                        this.$message({
                            message:'请输入昵称',
                            type:'error'
                        })
                        return
                    }
                    this.$post({
                        url:api.update_user_info,
                        data:{
                            nickname:this.username
                        }
                    }).then((res)=>{
                        this.$message({
                            message:res.data.msg,
                            type:'success'
                        })
                        this.$forceUpdate()
                    })
                }else if(i ==2){
                    if(!this.express){
                        this.$message({
                            message:'请输入收货地址',
                            type:'error'
                        })
                        return
                    }
                    this.$post({
                        url:api.bind_express,
                        data:{
                            shdz:this.express
                        }
                    }).then((res)=>{
                        this.$message({
                            message:"绑定成功",
                            type:'success'
                        })
                        this.getUserFn()
                    })
                }else if(i == 3){
                    this.$post({
                        url:api.update_pwd,
                        data:{
                            password:this.password,
                            new_password:this.new_password
                        }
                    })
                }
            },
            uploadChange(file, fileList){
                this.formDate = new FormData()
                this.formDate.set('file',file.raw)
            },
           async httpRequest(){
             let data =  await axios.post(api.url + api.up_avatar, this.formDate,{
                 headers:{
                     token:localStorage.getItem('token')
                 }
             })
               if(data.data.code == 0){
                   this.$message({
                       message:'修改成功',
                       type:'success'
                   })
                   this.getUserFn()
                   location.reload()
               }
            }
        }
    }
</script>

<style scoped lang="scss">
    .datum-row{
        background-color: #fff;
        .datum-title{
            font-size: 18px;
            font-weight: bold;
            padding: 23px 0 14px 21px;
            display: flex;
            align-items: flex-end;
            border-bottom: 1px dashed #DCDCDC;
            img{
                width: 27px;
                height: 26px;
                margin-right:8px;
            }
        }
        .datum{
            margin-top: 28px;
            padding-left: 20px;
        }
    }
    .userIcon{
        width: 22px;
        height: 20px;
        margin-right: 3px;
    }
    .upPhoto{
        padding:18px 20px;
        border-radius: 27px;
        background-color: #0D96FF;
        color: white;
    }
    .userInput{
        width: 440px;
        height: 46px;
        background: #F9F9F9;
        border: 1px solid #89CCFF;
        padding:0 12px;
        box-sizing: border-box;
        margin-top: 10px;
        input{
            background-color: transparent;
        }
    }
    .setPassword{
        width: 19px;
        height: 26px;
        margin-right: 6px;
    }
    .password{
        width: 440px;
        height: 46px;
        background: #F9F9F9;
        border: 1px solid #89CCFF;
        display: flex;
        align-items: center;
        padding-left: 12px;
        margin-bottom: 19px;
        input{
            flex: 1;
            background-color: transparent;
        }
    }
    .userSubmit{
        display: inline-block;
        font-size: 12px;
        padding: 5px 10px;
        background-color: #4C94FF;
        color: white;
        border-radius: 22px;
        cursor: pointer;

    }
    .Submit{
        width: 94px;
        height: 45px;
        background: #0D96FF;
        border-radius: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        margin-top: 17px;
    }
    .width454{
        width: 454px;
    }
    .inputstyle{
        weight:180px;
    }
    .textarea{
        width: 440px;
        height: 141px;
        background: #F9F9F9;
        border: 1px solid #89CCFF;
        padding:19px 14px;
        box-sizing: border-box;
        margin-top: 15px;
        textarea{
            width: 100%;
            height: 100%;
            background-color: transparent;
        }
    }
    input::-webkit-input-placeholder{
        color:#979797;
        font-size: 14px;
    }
    .inputstyle:focus{
        outline: none;
        border-color: #4C94FF;
    }
    .siteIcon{
        width: 20px;
        height: 22px;
        margin-right: 5px;
    }
    .photo{
        width: 120px;
        height: 120px;
        border: 2px solid #0D96FF;
        border-radius: 50%;
    }
</style>
